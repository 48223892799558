import { render, staticRenderFns } from "./ListCreative.vue?vue&type=template&id=a1ecb07a&scoped=true"
import script from "./ListCreative.vue?vue&type=script&lang=js"
export * from "./ListCreative.vue?vue&type=script&lang=js"
import style0 from "./ListCreative.vue?vue&type=style&index=0&id=a1ecb07a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1ecb07a",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QCard,QImg,QAvatar,QCardSection,QList,QItem,QItemSection,QItemLabel,QTooltip,QBtn,QTd,QToolbar,QSpace,QPagination});
