<template>
  <div>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('creatives') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('creatives')" to="/creative" />
        <q-breadcrumbs-el :label="$t('manage_creative')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-toolbar class="q-pr-none q-mb-sm">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        :label="$t('filter')"
        @click="filter = !filter"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('filter_creatives') }}
        </q-tooltip></q-btn
      >
      <q-btn
        unelevated
        color="primary"
        class="q-px-lg q-mr-sm"
        icon="mdi-plus"
        :label="$t('add')"
        no-caps
        to="/creative/manager/"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('add_creative') }}
        </q-tooltip>
      </q-btn>

      <q-btn
        unelevated
        color="warning"
        class="q-px-sm"
        icon="mdi-pencil-outline"
        label="Edicao de criativo em lote"
        no-caps
        @click="dialogEditCreative = true"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          Editar criativo em lote
        </q-tooltip>
      </q-btn>
    </q-toolbar>

    <q-dialog v-model="dialogEditCreative" persistent>
      <q-card style="min-width: 600px">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title> Edicao de criativo em lote </q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="onClose" />
        </q-toolbar>
        <div class="col-12 q-pa-lg">
          <!-- <span class="text-weight-medium">Passo 3</span> -->
          <q-banner class="bg-info text-white q-mb-md" rounded>
            <q-item>
              <q-item-section top avatar>
                <q-icon name="mdi-information-outline" size="lg" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-weight-medium">Atenção ao formato correto do arquivo! </q-item-label>
                <q-item-label caption lines="6" class="text-white"
                  >O arquivo deverá ser separado por ";" e poderá conter as seguintes colunas: "id", "title",
                  "description","image_url", "destination_url", "activation_date", expiration_date e "code". Em caso de
                  dúvidas, você pode fazer o download do nosso modelo
                  <a href="https://afilio-v3.s3.amazonaws.com/Template_Update_Criativo_Batch.csv">clicando aqui</a>
                  .
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-banner>
          <q-uploader flat bordered accept=".csv" class="full-width" @added="getCSV">
            <template v-slot:list="scope">
              <q-list separator>
                <div class="text-center" v-if="scope.files.length == 0">
                  <div class="text-h6 text-primary">Arraste e solte seus documentos aqui!</div>
                  <div class="text-caption">...ou clique para selecionar os arquivos do computador</div>
                </div>
                <q-item v-for="file in scope.files" :key="file.name">
                  <q-item-section>
                    <q-item-label class="full-width ellipsis">
                      {{ file.name }}
                    </q-item-label>

                    <q-item-label caption>
                      Status:
                      {{ file.__status }}
                    </q-item-label>

                    <q-item-label caption>
                      {{ file.__sizeLabel }} /
                      {{ file.__progressLabel }}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section v-if="file.__img" thumbnail class="gt-xs">
                    <img :src="file.__img.src" />
                  </q-item-section>

                  <q-item-section top side>
                    <q-btn class="gt-xs" size="12px" flat dense round icon="delete" @click="scope.removeFile(file)" />
                  </q-item-section>
                </q-item>
              </q-list>
            </template>
          </q-uploader>
        </div>
        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />

          <q-btn unelevated label="Salvar" no-caps padding="sm lg" color="primary" type="submit" @click="onSave" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-4">
          <span class="text-weight-medium q-mb-none">Status</span>
          <div class="q-gutter-xs text-caption">
            <q-radio v-model="params.status" val="1" :label="$t('activated')" />
            <q-radio v-model="params.status" val="3" :label="$t('disabled')" />
            <q-radio v-model="params.status" val="0" :label="$t('scheduled')" />
            <q-radio v-model="params.status" val="-1" :label="$t('pending_verification')" />
          </div>
        </div>
        <div class="col-2">
          <q-input outlined :label="$t('creative_id')" v-model="params.id" type="text" />
        </div>
        <div class="col-3">
          <select-request
            :v-model="params.type"
            outlined
            :label="$t('type')"
            endpoint="redirect?app=CREATIVE&path=/api/get/creative_type"
            option-label="description"
            option-value="id"
            @update="(value) => (params.type = value)"
          />
        </div>
        <div class="col-3">
          <select-request
            :v-model="params.campaign"
            outlined
            :label="$t('campaigns')"
            endpoint="redirect?app=ADVERTISER&path=/api/get/campaign"
            option-label="name"
            option-value="id"
            @update="(value) => (params.campaign = value)"
          />
        </div>
        <div class="col-3">
          <select-request
            :label="$t('category')"
            :v-model="params.category"
            outlined
            endpoint="redirect?app=CREATIVE&path=/api/get/category/filter"
            option-label="name"
            option-value="id"
            @update="(value) => (params.category = value)"
          />
        </div>
        <div class="col-3">
          <select-request
            :v-model="params.offer"
            outlined
            :label="$t('offer')"
            endpoint="redirect?app=CREATIVE&path=/api/get/offer/filter"
            option-label="name"
            option-value="id"
            @update="(value) => (params.offer = value)"
          />
        </div>
        <div class="col-3">
          <select-request
            :v-model="params.bannerSize"
            outlined
            :label="$t('banner_size')"
            endpoint="redirect?app=CREATIVE&path=/api/get/banner_size"
            option-label="size"
            option-value="id"
            @update="(value) => (params.bannerSize = value)"
          />
        </div>
        <div class="col-3">
          <q-input outlined v-model="params.idLink" :label="$t('shortened_link_id')" type="text" />
        </div>
        <div class="col-12 text-right">
          <!--          <q-btn-->
          <!--            color="primary"-->
          <!--            :label="$t('clear')"-->
          <!--            no-caps-->
          <!--            outline-->
          <!--            class="q-px-md q-mx-md"-->
          <!--            @click="clearFilter"-->
          <!--          />-->
          <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterCreative" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import CreativeMixin from '../../mixins/CreativeMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
export default {
  name: 'HeaderUser',
  mixins: [CreativeMixin],
  components: { SelectRequest },
  data() {
    return {
      dialogEditCreative: false,
      formDataCSV: null,
      filter: true,
      params: {
        id: null,
        idLink: null,
        name: null,
        type: null,
        campaign: null,
        category: null,
        offer: null,
        bannerSize: null,
        status: '1'
      }
    };
  },
  methods: {
    onSave() {
      let erro = false;
      if (!this.formDataCSV) {
        this.errorNotify('É necessário fazer o upload de um arquivo CSV.');
        erro = true;
      }
      if (!erro) this.importCSV();
    },

    async importCSV() {
      try {
        this.onLoading(true);

        const { status } = await this.$http.post(`${process.env.VUE_APP_CREATIVE}/api/upload_edit`, this.formDataCSV, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (status === 200 || status === 201) {
          this.successNotify('Criativo criado com sucesso');
          this.onClose();
        }
      } catch (error) {
        this.errorNotify(error.response.data.data.msg);
      } finally {
        this.onLoading(false);
      }
    },

    onClose() {
      this.dialogEditCreative = false;
    },

    async getCSV(file) {
      this.formDataCSV = new FormData();
      let [fileCSV] = file;
      this.formDataCSV.append('csv_file', fileCSV);
    },

    // clearFilter() {
    //     for (let i in this.params)
    //         this.params[i] = Array.isArray(this.params[i]) ? [] : null;
    // },

    // clearFilter() {
    //   this.params = {
    //     id: null,
    //     idLink: null,
    //     name: null,
    //     type: null,
    //     campaign: null,
    //     category: null,
    //     offer: null,
    //     bannerSize: null,
    //     status: "1",
    //   };
    //   this.ActionSetFilterCreative();
    //   this.getCreative();
    // },

    onFilterCreative() {
      let filterSelect = [];
      if (this.params.id) filterSelect.push(`id=${this.params.id}`);
      if (this.params.campaign) filterSelect.push(`campaign=${this.params.campaign.id}`);
      if (this.params.type) filterSelect.push(`type=${this.params.type.id}`);
      if (this.params.category) filterSelect.push(`category=${this.params.category.id}`);
      if (this.params.offer) filterSelect.push(`offer=${this.params.offer.id}`);
      if (this.params.bannerSize) filterSelect.push(`size=${this.params.bannerSize.id}`);
      if (this.params.status) filterSelect.push(`status=${this.params.status}`);
      else filterSelect.push(`status=1`);

      if (this.params.idLink) filterSelect.push(`shortlinkId=${this.params.idLink}`);

      this.ActionSetFilterCreative(filterSelect.join('%26'));
      this.getCreative();
    }
  }
};
</script>

<style></style>
