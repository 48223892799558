<template>
  <q-table flat bordered :data="creative" row-key="id" :pagination="pagination" grid>
    <template v-slot:item="props">
      <div class="q-pa-sm col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <q-card flat bordered class="q-pa-sm">
          <q-img
            :src="props.row.image_campaign_url"
            height="200px"
            contain
            v-if="['coupon', 'hyperlink', 'deal'].includes(props.row.type_name) && props.row.image_campaign_url"
          >
            <template v-slot:error>
              <div class="absolute-full flex flex-center bg-negative text-white">Cannot load image</div>
            </template>
          </q-img>
          <q-img
            :src="props.row.image_url"
            height="200px"
            contain
            v-else-if="props.row.type_name == 'image' && props.row.image_url"
          >
            <template v-slot:error>
              <div class="absolute-full flex flex-center bg-negative text-white">Cannot load image</div>
            </template>
          </q-img>
          <q-avatar
            v-else
            class="full-width"
            style="height: 200px"
            font-size="100px"
            :color="!$q.dark.isActive ? 'grey-3' : 'dark'"
            :text-color="!$q.dark.isActive ? 'grey-5' : 'white'"
            :icon="getIconType(props.row.type_name)"
          />
          <q-card-section class="q-pa-none">
            <q-list dense>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('ID') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-weight-bold text-positive">{{ props.row.id }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('campaign') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.campaign_name }} ({{ props.row.id_campaign }})</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">Status:</q-item-label>
                </q-item-section>
                <q-item-section class="text-weight-bold">
                  <q-item-label v-if="props.row.status == 2" class="text-success">Pendente</q-item-label>
                  <q-item-label v-if="props.row.status == 1" class="text-primary">{{ $t('active') }}</q-item-label>
                  <q-item-label v-if="props.row.status == 3" class="text-negative">{{ $t('disabled') }}</q-item-label>
                  <q-item-label v-if="props.row.status == 0" class="text-primary">{{ $t('scheduled') }}</q-item-label>
                  <q-item-label v-if="props.row.status == -1" class="text-success">{{
                    $t('pending_verification')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="
                  props.row.type_name == 'coupon' || props.row.type_name == 'deal' || props.row.type_name == 'hyperlink'
                "
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('title') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="limited-text"
                    >{{ props.row.title
                    }}<q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">{{
                      props.row.title
                    }}</q-tooltip></q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item
                v-if="
                  props.row.type_name == 'coupon' || props.row.type_name == 'deal' || props.row.type_name == 'hyperlink'
                "
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('description') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="limited-text"
                    >{{ props.row.description
                    }}<q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">{{
                      props.row.description
                    }}</q-tooltip></q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.type_name == 'emailmkt'">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('subject') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="limited-text"
                    >{{ props.row.subject
                    }}<q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">{{
                      props.row.subject
                    }}</q-tooltip></q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('type') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.type_description }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-if="props.row.type_name == 'coupon'">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('code') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.code }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-if="props.row.type_name == 'image'">
                <q-item-section>
                  <q-item-label>{{ $t('size') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.size }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('activation_date') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.activation_date">{{
                    $moment(props.row.activation_date).locale($t('format_date')).format('L')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('activation_time') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.activation_date">{{
                    $moment(props.row.activation_date).locale($t('format_date')).format('LT')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('expiration_date') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.expiration_date">{{
                    $moment(props.row.expiration_date).locale($t('format_date')).format('L')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('expiration_time') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.expiration_date">{{
                    $moment(props.row.expiration_date).locale($t('format_date')).format('LT')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('last_update') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.updated_at">{{
                    $moment(props.row.updated_at).locale($t('format_date')).format('L')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.destination_url">
                <q-item-section>
                  <q-item-label class="text-weight-bold">URL de destino:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label style="max-width: 50px">{{
                    props.row.destination_url.length > 23
                      ? props.row.destination_url.substring(0, 20) + '...'
                      : props.row.destination_url
                  }}</q-item-label>
                  <q-tooltip>
                    {{ props.row.destination_url }}
                  </q-tooltip>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <q-card-section>
            <q-btn
              color="positive"
              no-caps
              :label="$t('generate_tag')"
              class="full-width q-mb-sm"
              @click="openGenerateTag(props.row)"
              unelevated
            />
            <q-btn
              color="dark"
              :label="$t('edit')"
              class="full-width q-mb-sm"
              no-caps
              outline
              @click="editCreative(props.row)"
            />
            <q-btn
              v-if="props.row.status == -1"
              color="yellow"
              :label="$t('approve')"
              class="full-width"
              no-caps
              outline
              @click="approveCreative(props.row)"
            />
            <!-- :to="'/creative/manager/' + props.row.id" -->
          </q-card-section>
        </q-card>
      </div>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-pencil-outline"
          color="primary"
          :to="'/creative/manager/' + props.row.id"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Editar </q-tooltip>
        </q-btn>
        <q-btn
          unelevated
          round
          flat
          icon="mdi-account-off-outline"
          color="primary"
          @click="ActionSetDialogUser({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Desativar </q-tooltip>
        </q-btn>
        <q-btn
          unelevated
          round
          flat
          icon="mdi-trash-can-outline"
          color="negative"
          @click="ActionSetDialogUser({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Excluir </q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:bottom>
      <!--  <pagination-table
        @change="getCreative"
        :data="reqPagination"
        :pagination="pagination"
      /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>
<script>
import CreativeMixin from '../../mixins/CreativeMixin';
import CreativesService from '@/services/CreativesService';
//import PaginationTable from "../../widgets/PaginationTable.vue";
export default {
  //components: { PaginationTable },
  name: 'ListCreative',
  mixins: [CreativeMixin, CreativesService],
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 12
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getCreative(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  methods: {
    editCreative(creative) {
      this.ActionSetCreativeSelect(creative);
      console.log(creative.status);
      this.$router.push({ path: `/creative/manager/${creative.id}` });
    },

    async approveCreative(creative) {
      try {
        this.onLoading(true);

        if (creative.type_name != 'emailmkt') {
          // https://regex101.com/r/a9twc2/1
          /* eslint-disable */
          const regex =
            /((https?|ftp)\:\/\/)?([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([:\/\a-z0-9-.]*)\.([a-z]{2,5})(\:[0-9]{2,5})?(\/([a-z0-9+\$_-]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9!;:@&%=+\/\$_.-\|-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/gm;
          /* eslint-enable */

          const matched = creative.destination_url.match(regex);

          if (!matched) {
            this.errorNotify('A URL de destino do criativo é inválida e precisa ser revisada.');
            return;
          }
        }

        const { status } = await this.approveAdvertiserCreative(creative.id);

        if (status == 204) {
          this.successNotify('Criativo aprovado com sucesso!');
          this.getCreative();
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    openGenerateTag(creative) {
      this.ActionSetCreativeSelect(creative);
      this.$router.push({ path: `/creative/tag/${creative.id}` });
    },

    getIconType(type) {
      switch (type) {
        case 'coupon':
          return 'fas fa-ticket-alt';
        case 'emailmkt':
          return 'fas fa-envelope';
        case 'deal':
          return 'fas fa-percentage';
        case 'hyperlink':
          return 'fas fa-file-alt';
        default:
          return 'fas fa-envelope';
      }
    }
  },
  created() {
    this.getCreative(null, '%26perPage=' + this.pagination.rowsPerPage);
  }
};
</script>

<style scoped>
.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
